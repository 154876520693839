/* You can add global styles to this file, and also import other style files */

#loader ul {
  clear: both;
  display: block;
  padding: 0;
  text-align: center;
}

#loader li {
  width: 16px;
  height: 16px;
  background-color: #71B2C9;
  border-radius: 100%;
  margin: 0 3px;
  display: inline-block;
  -webkit-animation: dots 1.4s infinite ease-in-out;
  animation: dots 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#loader li:nth-of-type(3) {
  -webkit-animation-delay: .32s;
  animation-delay: .32s;
}

#loader li:nth-of-type(2) {
  -webkit-animation-delay: .16s;
  animation-delay: .16s;
}

@-webkit-keyframes dots {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  40% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@keyframes dots {
  0%, 100%, 80% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}
